import "@fontsource/nunito/300.css";
import "@fontsource/nunito/400.css";
import "@fontsource/nunito/400-italic.css";
import "@fontsource/nunito/700.css";

import cn from "classnames";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import parse from "html-react-parser";
import { useEffect, useState } from "react";

import BackgroundShape from "../../atoms/background-shape/BackgroundShape";
import ChatLink from "../../atoms/chat-link/ChatLink";
import Icons from "../../core/ui/images/icomoon/symbol-defs.svg";
import Footer from "../../core/ui/organisms/footer/Footer";
import CountryChangeSuggester from "../../molecules/country-change-suggester/CountryChangeSuggester";
import GoogleReviews from "../../organisms/./google-reviews/GoogleReviews";
import AppReimbursement from "../../organisms/app-reimbursement/app_reimbursement";
import CompanyValues from "../../organisms/company-values/CompanyValues";
import Faqs from "../../organisms/faqs/Faqs";
import Hero from "../../organisms/hero/Hero";
import KspList from "../../organisms/ksp-list/KspList";
import LandingBottomCta from "../../organisms/landing-bottom-cta/LandingBottomCta";
import Meta from "../../organisms/meta/meta";
import Navigation from "../../organisms/navigation/Navigation";
import PageWrapper from "../../organisms/page-wrapper/PageWrapper";
import Reimbursement from "../../organisms/reimbursement/reimbursement";
import withLandingWrapper from "../../organisms/with_landing_wrapper";
import { availableLocales, Locale } from "../../settings/countries";
import { PageId } from "../../settings/pages";
import useAxeptioScriptLoader from "../../utils/hooks/useAxeptioScriptLoader";
import { getCountryByLocale, shouldShowChatLink } from "../../utils/locale-configuration-utils";
import * as styles from "./Landing.module.scss";

const Landing = ({ data, location }) => {
  const {
    slug,
    locale,
    metaTitle,
    hero,
    metaDescription,
    benefitsType,
    benefits,
    reimbursement,
    showCompanyValuesSection,
    showAssociationsSection,
    showCommittedToAnimalsSection,
    appReimbursement,
    faqs,
    reviewsWidget,
    landingBottomCta,
    footer,
  } = data.contentfulLandingLocal;

  const alternateVersions = data.alternateVersions.nodes
    .map(({ localizedContent }) => localizedContent)
    .filter((item) => item && availableLocales.includes(item.locale));

  const countryCodeIso = getCountryByLocale(locale);
  const { i18n } = useI18next();

  useAxeptioScriptLoader(locale);
  const [isLanguageReady, setIsLanguageReady] = useState(false);
  const showChatLink = shouldShowChatLink(countryCodeIso, PageId.landing);

  const { t } = useTranslation();

  const hasAlternativeBenefitsSection = benefitsType === "alternative";

  useEffect(() => {
    const { language, changeLanguage } = i18n;

    const initializeLanguage = async () => {
      if (isLanguageReady) {
        return;
      }

      if (language === locale) {
        setIsLanguageReady(true);
      } else {
        await changeLanguage(locale);
        setIsLanguageReady(true);
      }
    };

    void initializeLanguage();
  }, [i18n, isLanguageReady, locale]);

  return (
    <>
      <Meta
        title={metaTitle}
        description={metaDescription}
        slug={slug}
        alternateVersions={alternateVersions}
        lang={locale as Locale}
        bodyClass={styles.landing}
        disableTranslations={undefined}
      />
      {isLanguageReady && (
        <PageWrapper>
          <Navigation
            location={location}
            countryCodeIso={countryCodeIso}
            adoptionClassName={styles.navigation}
          />
          <main className={cn(styles.main, "or-main")}>
            <CountryChangeSuggester
              countryCodeIso={countryCodeIso}
              adoptionClassName={styles.countryChangeSuggester}
            />

            <Hero data={hero} countryCodeIso={countryCodeIso} adoptionClassName={styles.hero} />

            {benefits && (
              <KspList
                data={benefits}
                variantName={hasAlternativeBenefitsSection ? "alternativeBenefits" : "benefits"}
                adoptionClassName={styles.kspList}
              />
            )}

            {reimbursement && (
              <Reimbursement data={reimbursement} adoptionClassName={styles.reimbursement} />
            )}

            {showCompanyValuesSection && (
              <CompanyValues
                variantName={"designedByPetParents"}
                title={parse(t("landing.designed_by_petparents.title"))}
                subtitle={t("landing.designed_by_petparents.subtitle")}
              >
                <div>
                  <figure>
                    <BackgroundShape variantName="companyValues" />
                    <StaticImage
                      placeholder="blurred"
                      src={"../../images/organisms/company-values/barkibu-dogs-and-humans.jpg"}
                      alt={t("landing.designed_by_petparents.image.1")}
                    />
                    <StaticImage
                      placeholder="blurred"
                      src={"../../images/organisms/company-values/videocall-with-vet.jpg"}
                      alt={t("landing.designed_by_petparents.image.2")}
                    />
                  </figure>
                  <p>{parse(t("landing.designed_by_petparents.description.paragraphs.1"))}</p>
                  <p>{parse(t("landing.designed_by_petparents.description.paragraphs.2"))}</p>
                  <ul>
                    {[1, 2, 3, 4].map((i) => (
                      <li key={i}>
                        {parse(t(`landing.designed_by_petparents.description.list_items.${i}`))}
                      </li>
                    ))}
                  </ul>
                </div>
              </CompanyValues>
            )}

            {showCommittedToAnimalsSection && (
              <CompanyValues
                title={parse(t("landing.committed_to_animals.title"))}
                subtitle={t("landing.committed_to_animals.subtitle")}
                variantName={"committedToAnimals"}
              >
                <ul>
                  <li>
                    <article>
                      <StaticImage
                        placeholder="blurred"
                        src="../../images/organisms/company-values/patri_chochi.jpg"
                        alt=""
                      />
                      <h3>{parse(t("landing.committed_to_animals.1.title"))}</h3>
                      <p>{parse(t("landing.committed_to_animals.1.description"))}</p>
                    </article>
                  </li>
                  <li>
                    <article>
                      <StaticImage
                        placeholder="blurred"
                        src="../../images/organisms/company-values/marta_viejito.jpg"
                        alt=""
                      />
                      <h3>{parse(t("landing.committed_to_animals.2.title"))}</h3>
                      <p>{parse(t("landing.committed_to_animals.2.description"))}</p>
                    </article>
                  </li>
                </ul>
              </CompanyValues>
            )}

            {showAssociationsSection && (
              <CompanyValues
                title={parse(t("landing.social_causes.title"))}
                subtitle={t("landing.social_causes.subtitle")}
                description={parse(t("landing.social_causes.description"))}
                variantName={"socialCauses"}
              >
                <ul>
                  <li>
                    <StaticImage
                      placeholder="blurred"
                      className="social-causes-logo-advocate"
                      src="../../images/organisms/company-values/nailted_badge.svg"
                      alt={t("landing.social_causes.nailted.alt")}
                    />
                  </li>
                  <li>
                    <StaticImage
                      placeholder="blurred"
                      className="social-causes-logo-nailted"
                      src="../../images/organisms/company-values/nailted.png"
                      alt={t("landing.social_causes.nailted.alt")}
                    />
                  </li>
                  <li>
                    <StaticImage
                      placeholder="blurred"
                      className="social-causes-logo-escan"
                      src="../../images/organisms/company-values/escan.jpg"
                      alt={t("landing.social_causes.escan.alt")}
                    />
                  </li>
                  <li>
                    <StaticImage
                      placeholder="blurred"
                      className="social-causes-logo-anaa"
                      src="../../images/organisms/company-values/anaa.jpg"
                      alt={t("landing.social_causes.anaa.alt")}
                    />
                  </li>
                </ul>
              </CompanyValues>
            )}

            {appReimbursement && (
              <AppReimbursement
                data={appReimbursement}
                adoptionClassName={styles.appReimbursement}
              />
            )}
            {faqs && (
              <Faqs data={faqs} countryCodeIso={countryCodeIso} forceHideContactLinks={true} />
            )}
            {reviewsWidget && <GoogleReviews data={reviewsWidget} />}
            {landingBottomCta && (
              <LandingBottomCta
                data={landingBottomCta}
                countryCodeIso={countryCodeIso}
                adoptionClassName={styles.homeBottomCta}
              />
            )}
          </main>

          <Footer data={footer} countryCode={countryCodeIso} adoptionClassName={styles.footer} />
          <BackgroundShape adoptionClassName={styles.backgroundShape} />
          <Icons />
          {showChatLink && <ChatLink countryCodeIso={countryCodeIso} />}
        </PageWrapper>
      )}
    </>
  );
};

export default withLandingWrapper(Landing);

export const query = graphql`
  query LandingQuery($id: String) {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    contentfulLandingLocal(id: { eq: $id }) {
      slug
      locale: node_locale
      metaTitle
      metaDescription
      hero {
        ...HeroFragment
      }
      reviewsWidgetId
      benefitsType
      benefits {
        ...KspFragment
      }
      reimbursement {
        ...ReimbursementFragment
      }
      showCompanyValuesSection
      showAssociationsSection
      showCommittedToAnimalsSection
      appReimbursement {
        ...AppReimbursementFragment
      }
      faqs {
        ...FaqsFragment
      }
      reviewsWidget {
        ...reviewsWidgetFragment
      }
      landingBottomCta {
        ...LandingBottomCtaFragment
      }
      footer {
        ...FooterFragment
      }
    }
    alternateVersions: allContentfulLandingGlobal {
      nodes {
        localizedContent {
          id
          slug
          locale: node_locale
        }
      }
    }
  }
`;
